/* eslint-disable react/jsx-filename-extension */
import { Component, Children, cloneElement } from 'react';
import cx from 'clsx';
import './tree-node-renderer.scss';

class TreeNode extends Component {
  /**
   * @override
   */
  render() {
    const {
      children,
      listIndex = 0,
      swapFrom,
      swapLength,
      swapDepth,
      scaffoldBlockPxWidth,
      lowerSiblingCounts = [0],
      connectDropTarget,
      isOver,
      draggedNode,
      canDrop,
      treeIndex,
      // treeId, // Delete from otherProps
      // getPrevRow, // Delete from otherProps
      // node, // Delete from otherProps
      // path, // Delete from otherProps
      rowDirection = 'ltr',
      // ...otherProps
    } = this.props;

    const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null;

    // Construct the scaffold representing the structure of the tree
    const scaffoldBlockCount = lowerSiblingCounts.length;
    /** @type {any[]} */
    const scaffold = [];
    lowerSiblingCounts.forEach(
      (
        /** @type {number} */
        lowerSiblingCount,
        /** @type {number} */
        i,
      ) => {
        let lineClass = '';
        if (lowerSiblingCount > 0) {
          // At this level in the tree, the nodes had sibling nodes further down

          if (listIndex === 0) {
            // Top-left corner of the tree
            // +-----+
            // |     |
            // |  +--+
            // |  |  |
            // +--+--+
            lineClass =
              'rst__lineHalfHorizontalRight rst__lineHalfVerticalBottom';
          } else if (i === scaffoldBlockCount - 1) {
            // Last scaffold block in the row, right before the row content
            // +--+--+
            // |  |  |
            // |  +--+
            // |  |  |
            // +--+--+
            lineClass = 'rst__lineHalfHorizontalRight rst__lineFullVertical';
          } else {
            // Simply connecting the line extending down to the next sibling on this level
            // +--+--+
            // |  |  |
            // |  |  |
            // |  |  |
            // +--+--+
            lineClass = 'rst__lineFullVertical';
          }
        } else if (listIndex === 0) {
          // Top-left corner of the tree, but has no siblings
          // +-----+
          // |     |
          // |  +--+
          // |     |
          // +-----+
          lineClass = 'rst__lineHalfHorizontalRight';
        } else if (i === scaffoldBlockCount - 1) {
          // The last or only node in this level of the tree
          // +--+--+
          // |  |  |
          // |  +--+
          // |     |
          // +-----+
          lineClass = 'rst__lineHalfVerticalTop rst__lineHalfHorizontalRight';
        }

        scaffold.push(
          <div
            key={`pre_${1 + i}`}
            style={{ width: scaffoldBlockPxWidth }}
            className={cx('rst__lineBlock', lineClass, rowDirectionClass)}
          />,
        );

        if (treeIndex !== listIndex && i === swapDepth) {
          // This row has been shifted, and is at the depth of
          // the line pointing to the new destination
          let highlightLineClass = '';

          if (listIndex === swapFrom + swapLength - 1) {
            // This block is on the bottom (target) line
            // This block points at the target block (where the row will go when released)
            highlightLineClass = 'rst__highlightBottomLeftCorner';
          } else if (treeIndex === swapFrom) {
            // This block is on the top (source) line
            highlightLineClass = 'rst__highlightTopLeftCorner';
          } else {
            // This block is between the bottom and top
            highlightLineClass = 'rst__highlightLineVertical';
          }

          let style;
          if (rowDirection === 'rtl') {
            style = {
              width: scaffoldBlockPxWidth,
              right: scaffoldBlockPxWidth * i,
            };
          } else {
            // Default ltr
            style = {
              width: scaffoldBlockPxWidth,
              left: scaffoldBlockPxWidth * i,
            };
          }

          scaffold.push(
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              style={style}
              className={cx(
                'rst__absoluteLineBlock',
                highlightLineClass,
                rowDirectionClass,
              )}
            />,
          );
        }
      },
    );

    let style;
    if (rowDirection === 'rtl') {
      style = { marginRight: scaffoldBlockPxWidth * (scaffoldBlockCount - 1) };
    } else {
      // Default ltr
      style = { marginLeft: scaffoldBlockPxWidth * (scaffoldBlockCount - 1) };
    }

    return connectDropTarget(
      <div
        // {...otherProps}
        style={{ height: 'auto' }}
        className={cx('rst__node', rowDirectionClass)}
      >
        {scaffold}

        <div className="rst__nodeContent" style={style}>
          {Children.map(
            children,
            (
              /** @type {React.ReactNode} */
              child,
            ) =>
              // @ts-ignore
              cloneElement(child, {
                isOver,
                canDrop,
                draggedNode,
              }),
          )}
        </div>
      </div>,
    );
  }
}

export default TreeNode;
